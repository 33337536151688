
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "74f1ad16-b4fc-4fdd-b4fd-28af123b120f"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/app/src/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
